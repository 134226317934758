<template>
  <v-app id="app" :style="[backgroundImage, backgroundColor]">
    <metainfo />
    <original-header v-if="!isAuthProcessing" />
    <router-view class="router-view" v-if="!isAuthProcessing" />
    <processing v-if="isProcessing" />
    <telop v-if="telop.show" />
    <original-footer v-if="!isAuthProcessing" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header.vue'
import Processing from '@/components/common/processing.vue'
import Telop from '@/components/common/telop'
import OriginalFooter from '@/components/common/footer.vue'

export default {
  components: { OriginalHeader, Processing, Telop, OriginalFooter },
  metaInfo () {
    return {
      title: '脳タイプ診断 - みんなで作る性格診断',
      description: '質問に答えるだけであなたの脳タイプが分かります。性格、欲求、取扱説明など、仕事や恋愛で使える結果が診断できます。',
      og: {
        title: '脳タイプ診断 - みんなで作る性格診断',
        url: 'https://brain.mikle.com',
        image: 'https://firebasestorage.googleapis.com/v0/b/braincheck-prod.appspot.com/o/ogp%2Fogp.jpg?alt=media&token=17ed51cf-d185-4462-8b81-8f20faf94ec1',
        description: '質問に答えるだけであなたの脳タイプが分かります。性格、欲求、取扱説明など、仕事や恋愛で使える結果が診断できます。'
      }
    }
  },

  async mounted () {
    // authの監視
    this.$store.dispatch('auth/onAuth')

    // メンテナンス情報の監視を開始
    this.$store.dispatch('maintenances/onMaintenance')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    },
    /**
     * @return {Object} 背景画像
     */
    backgroundImage () {
      return {
        '--backgroundImage': this.$store.getters.background.hasImage ?
          `url(${require('@/assets/img/background/background_image.png')})` :
          'none'
      }
    },
    /**
     * @return {Object} 背景色のRGBA値
     */
    backgroundColor () {
      return {
        '--backgroundColor': this.$store.getters.background.color
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

html {
  font-size: 10px !important;
}

#app {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  font-family: $notosans;
  color: $black_normal_color;
  background-color: var(--backgroundColor);
  background-image: var(--backgroundImage);
  background-repeat: repeat;
  background-blend-mode: multiply;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  outline: none;
}

input:focus,
select:focus {
  border: none;
  outline: 0;
}

.router-view {
  flex: 1;
}

.v-application a {
  color: $blue_normal_color;
}
</style>
